<template>
  <div class="home">
    <div class="nav">
      <img border="0" class="mainlogo" src="../assets/logo.jpg" alt="Webpage Logo" width="96" height="96">
      <h1>just another compilation (DEMO)</h1>

      <router-link class="nav" to="/">Home</router-link>
      <router-link class="nav" to="/AboutMe">About</router-link>

      <img border="0" class="githublogo" src="../assets/github.png" alt="GitHub Logo" width="32" height="32">
    </div>

    <div class="detail-page">
      <h2>Welcome to Styling</h2><br>
      <div class="detail-content">

        <h3>What's recommended to do first</h3><br>


        <a :href="url">https://css-tricks.com/the-shapes-of-css/</a>

      </div>

      <br>
      <address class="contact-item-link">
        2022 | Rookie Coders<br>
        Located: Zurich, Switzerland
      </address>
    </div>
  </div>

</template>

<script>

</script>

<style lang="scss" scoped>
.mainlogo {
  float: left;
  margin-left: 24px;
  margin-bottom: 32px;
  border-radius: 25%;
}

.githublogo {
  margin-top: 20px;
  float: right;
}

h1 {
  float: left;
  margin-left: 32px;
  padding: 16px 20px;
  display: flex;

}

h3,
span {
  color: #ccd94b;
  display: flex;
}

.nav {
  margin-top: 32px;
  padding: 1rem;
  overflow: hidden;
}

.nav a {
  float: left;
  margin: 0.5rem;
  padding: 16px 20px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

a:hover,
nav a.router-link-active {
  border-bottom: 2px solid #ccd94b;
}

.about {
  background-color: var(--midnight-blue);
  padding: 50px 25px;
  border-radius: var(--radius-20);
}

.contact-item-link {
  transition: var(--transition-1);
}

.contact-item-link:not(address):is(:hover, :focus) {
  color: var(--white);
}

.video-block {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  overflow: hidden;
  width: 75%;
  height: auto;
}

.video-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>