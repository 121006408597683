<template>
  <div class="home">
    <div class="nav">
      <img border="0" class="mainlogo" src="../assets/logo.jpg" alt="Webpage Logo" width="96" height="96">
      <h1>just another compilation (DEMO)</h1>

      <router-link class="nav" to="/">Home</router-link>
      <router-link class="nav" to="/AboutMe">About</router-link>

      <img border="0" class="githublogo" src="../assets/github.png" alt="GitHub Logo" width="32" height="32">
    </div>

    <div class="detail-page">
      <h2>Welcome to (Modern) JavaScript</h2><br>
      <div class="detail-content">

        <h3>What's recommended to do first</h3><br>

        <h4>Loops and Iteration @MDN</h4>
        <a :href="'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide#loops_and_iteration'" target="_blank">
          <br><span>developer.mozilla.org/JavaScript/Guide#loops_and_iteration</span><br><br>
          {{ url }}
        </a>

        <br>
        <h4>Practice your JavaScript 'String'-Skills:</h4>
        <a :href="'https://www.teaching-materials.org/javascript/exercises/strings'" target="_blank">
          <br><span>teaching-materials.org/javascript/exercises/strings</span><br><br>
          {{ url }}
        </a>

      </div><br>
      <hr>

      <br>
      <h4>Javascript Ebook</h4>
      <a :href="'https://eloquentjavascript.net/'" target="_blank">
        <br><img border="0" class="cookbook" src="../assets/js-cookbook.png" alt="JS Cookbook" width="250" height="250">
        {{ url }}
      </a>

      <address class="contact-item-link"><br>
        2022 | Rookie Coders<br>
        Located: Zurich, Switzerland
      </address>
    </div>
  </div>

  <component-to-re-render :key="DetailPageJS" />

</template>

<script>
export default {
  data() {
    return {
      DetailPageJS: 0,
    };
  },
  methods: {
    forceRerender() {
      this.DetailPageJS += 1;
    }
  }
}
</script>

<style lang="scss" scoped>
.mainlogo {
  float: left;
  margin-left: 24px;
  margin-bottom: 32px;
  border-radius: 25%;
}

.githublogo {
  margin-top: 20px;
  float: right;
}

h1 {
  float: left;
  margin-left: 32px;
  padding: 16px 20px;
  display: flex;

}

h3,
span {
  color: #ccd94b;
  display: flex;
}

.nav {
  margin-top: 32px;
  padding: 1rem;
  overflow: hidden;
}

.nav a {
  float: left;
  margin: 0.5rem;
  padding: 16px 20px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

a:hover,
nav a.router-link-active {
  border-bottom: 2px solid #ccd94b;
}

.about {
  background-color: var(--midnight-blue);
  padding: 50px 25px;
  border-radius: var(--radius-20);
}

.contact .section-title {
  text-align: left;
  margin-block-end: 30px;
}

.contact-form {
  margin-block-end: 25px;
}

.contact-input {
  background-color: var(--white);
  color: var(--manatee);
  border-radius: var(--radius-5);
  padding: 15px 25px;
  font-size: var(--fs-5);
  margin-block-end: 15px;
}

.contact-input::placeholder {
  color: inherit;
}

textarea.contact-input {
  resize: vertical;
  min-height: 100px;
  height: 180px;
  max-height: 300px;
  min-width: 100px;
  width: 470px;
  max-width: 600px;
}

.btn-submit {
  border: 1;
  color: var(--white);
  font-family: var(--ff-barlow);
  font-size: var(--fs-5);
  width: 12%;
  padding: 10px;
  border-radius: var(--radius-5);
  transition: var(--transition-2);
}

.btn-submit:is(:hover, :focus) {
  background-color: var(--white);
  color: #ccd94b;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-item:not(:last-child) {
  margin-block-end: 20px;
}

.contact-icon {
  background-color: var(--glaucous);
  font-size: 2.4rem;
  padding: 20px;
  border-radius: 50%;
}

.contact-item-title {
  color: var(--white);
  font-family: var(--ff-barlow);
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
}

.contact-item-link {
  transition: var(--transition-1);
}

.contact-item-link:not(address):is(:hover, :focus) {
  color: var(--white);
}
</style>