<template>
  <div class="home">
    <div class="nav">
      <img border="0" class="mainlogo" src="../assets/logo.jpg" alt="Webpage Logo" width="96" height="96">
      <h1>just another compilation (DEMO)</h1>

      <router-link class="nav" to="/">Home</router-link>
      <router-link class="nav" to="/AboutMe">About</router-link>


      <img border="0" class="githublogo" src="../assets/github.png" alt="GitHub Logo" width="32" height="32">

    </div>
    <h3>This is a Learning Page build on Vue.js Version 3.0 under construction</h3><br>
    <h4>
      The idea is to have an overview on different topics. It is also a playground for me to practice different
      functionalities and SCSS Styling. <br>Please check out my 'About Me-Page' for more Information, which
      includes a Contact Form, as well. The content will be as follows:<br>
    </h4><br>
    <ul class="no-bullets">
      <li>- Links to official documentations</li>
      <li>- Personal recomendations of YouTube-Tutorials</li>
      <li>- Helpful cheatsheets from external sources</li>
      <li>- Free Ebooks, if available</li>
      <li>- Not yet available: Code Snippets, installation instructions etc.</li><br>
      <hr><br>
    </ul>
    <router-link class="box" to="/DetailPageBasics">
      <p>Basics</p><img border="0" alt="HTML Logo" src="../assets/intro-logo.png" width="120" height="120">
    </router-link>
    <router-link class="box" to="/DetailPageHtml">
      <p>Hypertext Markup Language</p><img border="0" alt="HTML Logo" src="../assets/html-logo.png" width="120"
        height="120">
    </router-link>
    <router-link class="box" to="/DetailPageCss">
      <p>Styling</p><img border="0" alt="CSS Logo" src="../assets/css-logo.png" width="120" height="120">
    </router-link>
    <router-link class="box" to="/DetailPageJS">
      <p>(Modern) JavaScript</p><img border="0" alt="JavaScript Logo" src="../assets/javascript-logo.png" width="120"
        height="120">
    </router-link>
    <router-link class="box" to="/DetailPageVue">
      <p>JS Framework Version 3</p><img border="0" alt="Vue JS Logo" src="../assets/vue-logo.png" width="120"
        height="120">
    </router-link>
    <router-link class="box" to="/DetailPageAngular">
      <p>Angular</p><img border="0" alt="Angular Logo" src="../assets/angular-logo.png" width="120" height="120">
    </router-link>
    <router-link class="box" to="/DetailPagePython">
      <p>Python Version 3</p><img border="0" alt="Phyton Logo" src="../assets/python-logo.png" width="120" height="120">
    </router-link>
    <router-link class="box" to="/DetailPageTools">
      <p>Tools</p><img border="0" alt="HTML Logo" src="../assets/tools-logo.png" width="120" height="120">
    </router-link>

    <address class="contact-item-link">
      2022 | Rookie Coders<br>
      Located: Zurich, Switzerland
    </address>

  </div>

</template>

<script>

</script>

<style lang="scss">
.mainlogo {
  float: left;
  margin-left: 24px;
  margin-bottom: 32px;
  border-radius: 25%;
}

.githublogo {
  margin-top: 20px;
  float: right;
}

img {
  border-radius: 15px;
}

h1 {
  float: left;
  margin-left: 32px;
  padding: 16px 20px;
  display: flex;
}

.nav {
  margin-top: 32px;
  padding: 1rem;
  overflow: hidden;
}

.nav a {
  float: left;
  margin: 0.5rem;
  padding: 16px 20px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

a:hover,
nav a.router-link-active {
  border-bottom: 2px solid #ffffff;
}

.box {
  background-image: linear-gradient(to right, #ffffff 50%, #ccd94b);
}

ul.no-bullets {
  text-align: left;
  list-style-type: none;
  /* Remove bullets */
  padding: 0;
  /* Remove padding */
  margin-left: 420px;
  /* Remove margins */
}

p {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  position: fixed;
  top: 0;
}
</style>