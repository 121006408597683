<template>
    
  <div id="app">
    
    <SideBar />
    <menu-icon />
    <router-view/>
    
  </div>
    
</template>

<script>

import SideBar from './components/SideBar.vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import 'vue-material-design-icons/styles.css';

export default {
  name: 'App',
  components: {
    
    SideBar,
    MenuIcon,
        
  }
}
  
</script>

<style lang="scss">
#app {
  display: flex;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
body {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: rgb(5, 5, 45);
}
h1 {
    color:#ccd94b;
}
.box {
    padding: 100px 0;
    width: 270px;
    height: 250px;
    text-align: center;
    background-color:rgb(5, 5, 45);
    background-image: linear-gradient(to right, #ffffff 50%, #ccd94b);
    background-size: 200%;
    margin: 20px;
    display: inline-block;
  //--c: #ccd94b; /* the color */
  --b: .04em;    /* border length*/
  --d: 12px;    /* the cube depth */
  --h: 1.2em;   /* the height */
  
  --_s: calc(var(--d) + var(--b));

  line-height: var(--h);
  color: rgb(5, 5, 45);
  text-shadow: 
    0 calc(-1*var(--_t,0em)) var(--c), 
    0 calc(var(--h) - var(--_t,0em)) rgb(5, 5, 45);
  border: solid #ccd94b;
  overflow: hidden;
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background:
    linear-gradient(var(--c) 0 0) 100% 100%
     /101% var(--_p,0%) no-repeat,
    conic-gradient(at left var(--d)  bottom var(--d),
      #0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
    conic-gradient(at left var(--_s) bottom var(--_s),
      #0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
  transform: translate(calc(var(--d)/-1),var(--d));
  clip-path: 
    polygon(
     var(--d) 0%, 
     var(--d) 0%, 
     100% 0%, 
     100% calc(100% - var(--d)),
     100% calc(100% - var(--d)),
     var(--d) calc(100% - var(--d))
    );
  transition: 0.5s;
}
.box:hover {
  transform: translate(0,0);
  clip-path: 
    polygon(
     0% var(--d), 
     var(--d) 0%, 
     100% 0%, 
     100% calc(100% - var(--d)), 
     calc(100% - var(--d)) 100%, 
     0% 100%
   );
  --_t: var(--h);
  --_p: 105%;

}
:root {
	--primary: #ccd94b;
	--primary-alt: #ccd94b;
	--grey: #64748b;
	--dark: #1e293b;
	--dark-alt: #334155;
	--light: ##ffffff;
	--sidebar-width: 250px;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Helvetica, sans-serif;
}
button {
	cursor: pointer;
	appearance: none;
	border: none;
	outline: none;
	background: none;
}

</style>
