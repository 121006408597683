<template>
  <div class="home">
    <div class="nav">
      <img border="0" class="mainlogo" src="../assets/logo.jpg" alt="Webpage Logo" width="96" height="96">
      <h1>just another compilation (DEMO)</h1>

      <router-link class="nav" to="/">Home</router-link>
      <router-link class="nav" to="/AboutMe">About</router-link>

      <img border="0" class="githublogo" src="../assets/github.png" alt="GitHub Logo" width="32" height="32">
    </div>

    <div class="about">

      <h1>Contact Information</h1><br>
      <router-view />
      <h3 class="section-subtitle">Hi, I am Beni Weinmann on the way of being a Junior Web Developer.</h3>
      <h3 class="h2 section-title">I am learning to develop Web Solutions, being on Beginner Level.</h3><br>

      <img border="0" alt="My name is Ben." src="../assets/MyNameIs.jpg" width="290" height="120">

      <br><br>
      <h4 class="section-text">
        Zurich Switzerland is where I live and my year is 1982. My hobbies are cooking for friends and family & visiting
        music
        concerts.<br>
        I am also interested in videogames, home electronics & movies. My native language is German.
      </h4><br>
      <h4 class="section-text">
        Quick understanding and social skills are my strengths. Weigh up benefits and threats take my attention.<br>
      </h4><br>
      <h4 class="section-text">
        What I would like, is to find a first Internship and later on a hirement as a Web Developer. Originally, I do
        have
        a commercial degree<br>with experience in ERP, purchasing, contract management, plus projectmanagement.
      </h4><br><br>
      <h2 class="h2 section-title">Leave me a Feedback</h2><br>

      <div class="wrapper">
      </div>
      <form ref="form" class="form" @submit.prevent="sendMail">

        <input type="text" name="name" placeholder="Name" required :value="inputFieldReset" class="contact-input">
        <input type="email" name="email" placeholder="Email" required :value="inputFieldReset"
          class="contact-input"><br>
        <input type="text" name="company" placeholder="Company (optional)" :value="inputFieldReset"
          class="contact-input">
        <input type="text" name="title" placeholder="Topic" required :value="inputFieldReset" class="contact-input"><br>
        <textarea name="message" placeholder="Message" required :value="inputFieldReset"
          class="contact-input"></textarea><br>
        <span class="material-icons">send</span><button type="submit" class="btn-submit">Send Message</button>
      </form><br>

      <address class="contact-item-link">
        2022 | Rookie Coders<br>
        Located: Zurich, Switzerland
      </address>
    </div>
  </div>

</template>

<script>
//import emailjs from 'emailjs-com';
//import {ref} from 'vue';

/*  setup() {
    const form = ref(null);
    const inputFieldReset = ref(null);

    const sendMail = () => {
        emailjs.sendForm('service_ozt3x2m', 'template_p32brq6', form.value, 'Ayhf88pxv39QhPhkF')
        .then(() => {
          alert('Message sent!')
          inputFieldReset.value = " ";
        }, (error) => {
          alert('Message not sent', error);
        }); 
      }
    },
  return: {
    form,
    inputFieldReset,
    sendMail
  }*/


</script>

<style lang="scss" scoped>
.mainlogo {
  float: left;
  margin-left: 24px;
  margin-bottom: 32px;
  border-radius: 25%;
}

.githublogo {
  margin-top: 20px;
  float: right;
}

h1 {
  float: left;
  margin-left: 32px;
  padding: 16px 20px;
  display: flex;

}

h3 {
  display: flex;
}

.section-text {
  text-align: left;
  margin-left: 52px;
}

.nav {
  margin-top: 32px;
  padding: 1rem;
  overflow: hidden;
}

.nav a {
  float: left;
  margin: 0.5rem;
  padding: 16px 20px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

a:hover,
nav a.router-link-active {
  border-bottom: 2px solid #ccd94b;
}

.about {
  background-color: var(--midnight-blue);
  padding: 50px 25px;
  border-radius: var(--radius-20);
}

.contact .section-title {
  text-align: left;
  margin-block-end: 30px;
}

.contact-form {
  margin-block-end: 25px;
}

.contact-input {
  background-color: var(--white);
  color: var(--manatee);
  border-radius: var(--radius-5);
  padding: 15px 25px;
  font-size: var(--fs-5);
  margin-block-end: 15px;
}

.contact-input::placeholder {
  color: inherit;
}

textarea.contact-input {
  resize: vertical;
  min-height: 100px;
  height: 180px;
  max-height: 300px;
  min-width: 100px;
  width: 470px;
  max-width: 600px;
}

.btn-submit {
  border: 1;
  color: var(--white);
  font-family: var(--ff-barlow);
  font-size: var(--fs-5);
  width: 12%;
  padding: 10px;
  border-radius: var(--radius-5);
  transition: var(--transition-2);
}

.btn-submit:is(:hover, :focus) {
  background-color: var(--white);
  color: #ccd94b;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-item:not(:last-child) {
  margin-block-end: 20px;
}

.contact-icon {
  background-color: var(--glaucous);
  font-size: 2.4rem;
  padding: 20px;
  border-radius: 50%;
}

.contact-item-title {
  color: var(--white);
  font-family: var(--ff-barlow);
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
}

.contact-item-link {
  transition: var(--transition-1);
}

.contact-item-link:not(address):is(:hover, :focus) {
  color: var(--white);
}
</style>