import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutMe from '../views/AboutMe.vue'
import DetailPageBasics from '../views/DetailPageBasics.vue'
import DetailPageHtml from '../views/DetailPageHtml.vue'
import DetailPageCss from '../views/DetailPageCss.vue'
import DetailPageJS from '../views/DetailPageJS.vue'
import DetailPageVue from '../views/DetailPageVue.vue'
import DetailPageAngular from '../views/DetailPageAngular.vue'
import DetailPagePython from '../views/DetailPagePython.vue'
import DetailPageTools from '../views/DetailPageTools.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/AboutMe',
    name: 'AboutMe',
    component: AboutMe
  },
  {
    path: '/DetailPageBasics',
    name: 'DetailPageBasics',
    component: DetailPageBasics
  },
  {
    path: '/DetailPageHtml',
    name: 'DetailPageHtml',
    component: DetailPageHtml
  },
  {
    path: '/DetailPageCss',
    name: 'DetailPageCss',
    component: DetailPageCss
  },
  {
    path: '/DetailPageJS',
    name: 'DetailPageJS',
    component: DetailPageJS
  },
  {
    path: '/DetailPageVue',
    name: 'DetailPageVue',
    component: DetailPageVue
  },
  {
    path: '/DetailPageAngular',
    name: 'DetailPageAngular',
    component: DetailPageAngular
  },
  {
    path: '/DetailPagePython',
    name: 'DetailPagePython',
    component: DetailPagePython
  },
  {
    path: '/DetailPageTools',
    name: 'DetailPageTools',
    component: DetailPageTools
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
